import React, { useState, useEffect } from "react";
import { useAppDispatch } from "./index";
import { setSearch } from "../redux/slices/Search/search";
import { getQueryParameters } from "../utils/helpers";

export const useLocationSearch = (): {
  blockLocationAccess: boolean;
  setBlockLocationAccess: React.Dispatch<React.SetStateAction<boolean>>;
} => {
  const dispatch = useAppDispatch();
  const [blockLocationAccess, setBlockLocationAccess] =
    useState<boolean>(false);
  useEffect(() => {
    const getPosition = (position: { coords: any }) => {
      const coordinates = position.coords;
      const payload = {
        latitude: coordinates.latitude,
        longitude: coordinates.longitude,
        searchString: "My Current Location",
        searchType: "",
      };

      coordinates && dispatch(setSearch(payload));
    };
    const getPositionError = () => {
      setBlockLocationAccess(true);
    };
    (async () => {
      const queryParams = getQueryParameters(location.search);
      if (queryParams !== null && queryParams.nearme === true) {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            getPosition,
            getPositionError
          );
        }
      }
    })();
  }, []);
  return { blockLocationAccess, setBlockLocationAccess };
};
