// External Package Imports
import React, { useEffect } from "react";
import { graphql } from "gatsby";

// Type and Interface Imports
import { IHotelSearchProps } from "../pageHelpers/HotelSearch/HotelSearchProps";
import { Constants } from "../@types/Constants";

// Redux and Custom Hook Imports
import { useAppSelector } from "../hooks";
import { searchSelector } from "../redux/slices/Search/search";
import useBrandBias from "../hooks/useBrandBias";

// Component Imports
import Layout from "../components/global/Layout/Layout";
import SEO from "../components/global/SEO/SEO";
import SearchResult from "../components/common/Search/SearchResult/SearchResult";
import ParagraphLayout from "../components/common/ParagraphLayout/ParagraphLayout";
import DeepLinking from "../components/common/DeepLinking/DeepLinking";
import { Container } from "../pageHelpers/HotelSearch/HotelSearchHelpers";

// Utility and Helper Imports
import { addPageTypeInGTMDataLayer } from "../utils/datalayers";
import { getParagraph } from "../utils/paragraphHelpers";
import { useLocationSearch } from "../hooks/useLocationSearch";

const HotelSearch: React.FC<IHotelSearchProps> = (props) => {
  const searchData = useAppSelector(searchSelector);
  const { blockLocationAccess, setBlockLocationAccess } = useLocationSearch();
  const page = props.data.page;
  const unlockBannerShow = page ? page.field_show_unlock_banner : false;
  const fieldShowFilter = page ? page.field_show_filter : false;
  const paragraphsAboveSearch =
    page && page.relationships?.paragraphs
      ? page.relationships.paragraphs.map(getParagraph)
      : [];
  const paragraphsBelowSearch =
    page && page.relationships?.paragraphsBelowSearch
      ? page.relationships.paragraphsBelowSearch.map(getParagraph)
      : [];

  const hotelSearchFilters = props.data.allRlhsite.edges.length
    ? props.data.allRlhsite.edges[0].node.field_filter_selected
    : [];

  useBrandBias();
  useEffect(() => {
    if (searchData.searchString != "My Current Location") {
      setBlockLocationAccess(false);
    }
  }, [searchData]);

  useEffect(() => {
    try {
      addPageTypeInGTMDataLayer("search");
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  }, []);

  const pageBanners = paragraphsAboveSearch.filter(
    (p: { props: { paragraphtype: string } }) => {
      return p
        ? p.props.paragraphtype === Constants.PARAGRAPH_PAGE_BANNER
        : false;
    }
  );
  return (
    <Layout>
      <SEO
        title="Hotel Search"
        includeLeaflet
        koddiTitle="Hotel-Search"
        noIndex
      />
      <DeepLinking location={props.location} pageName={"hotel-search"} />
      {pageBanners}
      <ParagraphLayout {...paragraphsAboveSearch} />
      <Container>
        <SearchResult
          blockLocationAccess={blockLocationAccess}
          location={props.location}
          fieldShowFilter={fieldShowFilter}
          unlockBannerShow={unlockBannerShow}
          hotelSearchFilters={hotelSearchFilters}
          pageType="hotel-search"
        />
      </Container>
      <ParagraphLayout {...paragraphsBelowSearch} />
    </Layout>
  );
};
export const pageQuery = graphql`
  query HotelSearchQuery {
    page: nodePage(path: { alias: { eq: "/v1/hotel-search" } }) {
      id
      field_show_unlock_banner
      field_show_filter
      relationships {
        paragraphs: field_sections {
          type: __typename
          ...ParagraphPageBanner
          ...ParagraphPageIntro
          ...ParagraphMediaCta
          ...ParagraphPageTeaser
          ...ParagraphTeaserList
          ...ParagraphBody
          ...ParagraphFrequentlyAskedQuestions
        }
        paragraphsBelowSearch: field_sections_below_search {
          type: __typename
          ...ParagraphPageIntro
          ...ParagraphMediaCta
          ...ParagraphPageTeaser
          ...ParagraphTeaserList
          ...ParagraphBody
          ...ParagraphFrequentlyAskedQuestions
        }
      }
    }
    allRlhsite(filter: { machine_name: { eq: "sonesta" } }) {
      edges {
        node {
          field_filter_selected
        }
      }
    }
  }
`;
export default HotelSearch;
